import React from "react";

import gradient from "../assets/images/bg-testimonials.svg";

const Testimonials = () => {
    return (
        <section id="testimonials" className="relative pb-[150px] lg:pb-[300px]">
            <div className="max-w-screen-xl container mx-auto px-5 lg:px-3">
                <div className="flex flex-wrap">
                    <h2 className="w-full lg:w-2/5 uppercase text-40 lg:text-57 font-700">Feedback from
                        <br /> our clients
                    </h2>
                    <div className="w-full lg:w-3/5 flex flex-col mt-6 lg:mt-0 pl-0 lg:pl-[100px]">
                        <div className="flex items-center mt-8 lg:mt-4">
                            <div className="w-[50px] h-[1px] bg-darkGray mr-3"></div>
                            <h2 className="text-22 uppercase">Mobicom, Inc.</h2>
                        </div>
                        <h6 className="text-22 mt-[24px] lg:mt-[51px]">
                            We've been working with Pi&People for more than a year now. Our previous experience with outsource teams was not always positive.
                            <br /><br />
                            In the case of Pi&People, everything is perfect: adequate estimation and work on time, the desire to dive into the details, the desire to make a good product, a complete understanding of what they are doing and why they are doing that...
                        </h6>
                    </div>
                </div>
            </div>
            <div className='bg-left absolute -z-50 -top-[350px]'>
                <img className='-ml-[50px] h-[1200px]' src={gradient} />
            </div>
        </section>
    )
}

export default Testimonials