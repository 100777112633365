import React from "react"

import team from '../assets/images/team.png'
import bg_rounded from '../assets/images/bg-rounded.svg'

const Team = () => {
    return (
        <section id='team' className="">
            <div className="max-w-screen-xl container mx-auto px-5 lg:px-3">
                <div className="flex items-center">
                    <div className="w-[50px] h-[1px] bg-darkGray mr-6"></div>
                    <h2 className="text-40 lg:text-57 font-700">OUR TEAM</h2>
                </div>
            </div>
            <img className="w-full mx-auto my-[50px] lg:my-[100px]" src={team}></img>
            <div className="max-w-screen-xl container mx-auto px-5 lg:px-3 text-center">
                <p className="text-22 mb-[50px] lg:mb-[100px]">
                    In our team, developers work alongside designers, strategists and analysts. Pi&People doesn't do cookie-cutter solutions and we build products exactly as they were during the design phase, no short cuts or simplifications.
                    <br /><br />
                    We're driven by user-centered design that drives productivity and increases revenue. Our expertise and ingenuity are remarkable, yet we always strive to outdo and outperform our previous achievements.
                </p>
                <div className="flex flex-wrap justify-center gap-5 mt-4 lg:mt-0 lg:gap-8 mb-[150px] lg:mb-[300px]">
                    <div className="relative w-[250px] h-[200px] lg:h-auto flex flex-col justify-center items-center">
                        <h4 className="font-800 text-40 lg:text-48">10+</h4>
                        <h6 className="px-2 mt-3 uppercase text-20 md:text-22 lg:text-24">Members</h6>
                        <img className="absolute -z-1" src={bg_rounded}></img>
                    </div>
                    <div className="relative w-[250px] h-[200px] lg:h-auto flex flex-col justify-center items-center">
                        <h4 className="font-800 text-40 lg:text-48">30+</h4>
                        <h6 className="px-2 mt-3 uppercase text-20 md:text-22 lg:text-24">Completed projects</h6>
                        <img className="absolute -z-1" src={bg_rounded}></img>
                    </div>
                    <div className="relative w-[250px] h-[200px] lg:h-auto flex flex-col justify-center items-center">
                        <h4 className="font-800 text-40 lg:text-48">10</h4>
                        <h6 className="px-2 mt-3 uppercase text-20 md:text-22 lg:text-24">Years of experience</h6>
                        <img className="absolute -z-1" src={bg_rounded}></img>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Team