import React from "react";

import logo_1 from '../assets/images/logo-1.svg'
import logo_2 from '../assets/images/logo-2.svg'
import logo_3 from '../assets/images/logo-3.svg'

const Partners = () => {
    return (
        <section id="partners" className="max-w-screen-xl container mx-auto px-5 lg:px-3 mb-[170px]">
            <h2 className="text-40 lg:text-57 font-700">OUR Partners</h2>
            <div className="mt-[68px] flex justify-between gap-4 lg:gap-0">
                <img className="grayscale w-1/3 max-h-[50px] lg:max-h-[100px]" src={logo_1}></img>
                <img className="grayscale w-1/3 max-h-[50px] lg:max-h-[100px]" src={logo_3}></img>
                <img className="grayscale w-1/3 max-h-[50px] lg:max-h-[100px]" src={logo_2}></img>
            </div>
            <div className="text-center mt-[78px]">
                <a className="uppercase text-22 lg:text-24 text-[#7D7F83]">And Much more 10+</a>
            </div>
        </section>
    )
}

export default Partners