import React from "react";

const Contact = () => {
    return (
        <section id="contact" className="bg-gray py-[60px] lg:py-[100px] text-black">
            <div className="max-w-screen-xl container mx-auto px-5 lg:px-3">
                <h4 className="uppercase font-600 leading-none text-57 pb-[70px]">
                    Hey! <br /> Tell us all the things
                </h4>
                <h5 className="uppercase font-300 text-28">I’m interested in</h5>
                <div className="w-full lg:w-3/5 flex mt-[24px] mb-[80px] font-300 flex-wrap gap-4 lg:gap-6">
                    <div className="border border-white rounded-full py-2 px-3 flex items-center">
                        <p className="uppercase text-16">Site from scratch</p>
                    </div>
                    <div className="border border-white rounded-full py-2 px-3 flex items-center">
                        <p className="uppercase text-16">App from scratch</p>
                    </div>
                    <div className="border border-white rounded-full py-2 px-3 flex items-center">
                        <p className="uppercase text-16">UX / UI design</p>
                    </div>
                    <div className="border border-white rounded-full py-2 px-3 flex items-center">
                        <p className="uppercase text-16">Branding</p>
                    </div>
                    <div className="border border-white rounded-full py-2 px-3 flex items-center">
                        <p className="uppercase text-16">Mobile Development</p>
                    </div>
                    <div className="border border-white rounded-full py-2 px-3 flex items-center">
                        <p className="uppercase text-16">Web Development</p>
                    </div>
                    <div className="border border-white rounded-full py-2 px-3 flex items-center">
                        <p className="uppercase text-16">Maintenance</p>
                    </div>
                </div>
                <div className="max-w-[483px]">
                    <input type="text" className="mb-[70px] uppercase bg-gray px-4 w-full py-4 bg-opacity-0 border-b border-b-darkGray" placeholder="your company"></input>
                    <input type="text" className="mb-[70px] uppercase bg-gray px-4 w-full py-4 bg-opacity-0 border-b border-b-darkGray" placeholder="your name"></input>
                    <input type="email" className="mb-[70px] uppercase bg-gray px-4 w-full py-4 bg-opacity-0 border-b border-b-darkGray" placeholder="your email"></input>
                    <input type="text" className="mb-[70px] uppercase bg-gray px-4 w-full py-4 bg-opacity-0 border-b border-b-darkGray" placeholder="Tell us about your business"></input>
                    <button className="border border-white rounded-full py-2 px-5 flex items-center">Send request</button>
                </div>
            </div>
        </section>
    )
}

export default Contact