import React from "react";

import text_img from '../assets/images/follow-text.svg'

const FollowUs = () => {
    return (
        <section id="philosophy" className="py-[100px]">
            <div className="max-w-screen-xl container mx-auto px-5 lg:px-3">
                <img className="w-2/3 lg:w-auto" src={text_img}></img>
                <div className="text-24 uppercase mt-[48px] mb-[24px]">Social media and contacts</div>
            </div>
            <div className="social-item text-32 py-5 border-y border-y-lightGray">
                <div className="max-w-screen-xl container mx-auto px-5 lg:px-3">
                    <a href="#" className="uppercase">instagram</a>
                </div>
            </div>
            <div className="social-item text-32 py-5 border-y border-y-lightGray">
                <div className="max-w-screen-xl container mx-auto px-5 lg:px-3">
                    <a href="#" className="uppercase">Facebook</a>
                </div>
            </div>
            <div className="social-item text-32 py-5 border-y border-y-lightGray">
                <div className="max-w-screen-xl container mx-auto px-5 lg:px-3">
                    <a href="#" className="uppercase">contact</a>
                </div>
            </div>
        </section>
    )
}

export default FollowUs