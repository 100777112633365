import React from "react";

import footer_logo from '../assets/images/footer-logo.svg'
import fb from '../assets/images/facebook.svg'
import linkedin from '../assets/images/linkedin.svg'
import arrow_next from '../assets/images/arrow-next.svg'

const Footer = () => {
    return (
        <section id="footer" className="bg-dark pt-[86px]">
            <div className="max-w-screen-xl container mx-auto px-5 pb-[36px] lg:px-0 border-b border-b-white">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 font-400 ">
                    <div className="col-span-1">
                        <img src={footer_logo}></img>
                        <div className="mt-[29px]">
                            <a tel="+976 0000-0000" className="uppecase text-22" >+976 9499-8976</a>
                        </div>
                        <div className="mt-[12px]">
                            <a mailto="info@piandpeople.com" className="uppecase text-22" >info@piandpeople.com</a>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="mt-[40px]">
                            <p className="uppecase text-22" >Quick Links</p>
                        </div>
                        <div className="mt-[24px]">
                            <a mailto="info@piandpeople.com" className="text-textLight text-22" >Our team</a>
                        </div>
                        <div className="mt-[12px]">
                            <a mailto="info@piandpeople.com" className="text-textLight text-22" >Send request</a>
                        </div>
                    </div>
                    <div className="col-span-1 h-full flex flex-col justify-end">
                        <div className="mt-[24px]">
                            <a href="#" className="text-textLight text-22" >Development process</a>
                        </div>
                        <div className="mt-[12px]">
                            <a href="#" className="text-textLight text-22" >Our services</a>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="mt-[40px]">
                            <p className="uppecase text-22 uppercase" >Subscribe</p>
                        </div>
                        <div className="mt-[24px] flex">
                            <input className="w-full p-3 rounded-sm rounded-e-none h-[48px]" placeholder="Get product updates"></input>
                            <button className="flex items-center h-[48px] p-4 bg-hex rounded-e-sm"><img src={arrow_next}></img></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-screen-xl container mx-auto px-5 pb-[36px] lg:px-0 mt-[38px] flex justify-between items-center">
                <div className="flex">
                    <a href="#" className="mr-2">
                        <img src={fb}></img>
                    </a>
                    <a href="#">
                        <img src={linkedin}></img>
                    </a>
                </div>
                <div className="text-16">
                    © {new Date().getFullYear} ПИ ЭНД ПЮПЛ ХХК. All rights reserved
                </div>
            </div>
        </section>
    )
}

export default Footer