import React from 'react';
import logo from '../assets/images/logo.svg'

const Header = () => {
  return (
    <header>
      <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 pt-[15px] md:pt-[30px] lg:pt-[60px]">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <a href="https://flowbite.com" className="flex items-center">
            <img
              src={logo}
              className="mr-3 h-6 sm:h-9"
              alt="Pi&People Logo"
            />
          </a>
          <div className="flex items-center lg:order-2">
            <a
              href="#"
              className="uppercase text-white "
            >
              Contact
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
