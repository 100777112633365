import React from "react"
const Hero = () => {
    return (
        <section id="hero" className="my-[80px] md:my-[150px] mb-[200px] md:mb-[150px]">
            <h1 className='text-white text-48 md:text-4xl lg:text-5xl font-600 max-w-[735px] leading-[60px] md:leading-[90px] lg:leading-[150px]'>
                WE <span className='text-lightHex'>{`{ create }`}</span> <br /> DIGITAL SOLUTIONS_
            </h1>
        </section>
    )
}

export default Hero