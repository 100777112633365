import React from "react";

import text_img from '../assets/images/philosophy-text.svg'

const Philosohy = () => {
    return (
        <section id="philosophy" className="bg-gray py-[100px]">
            <div className="max-w-screen-xl container mx-auto px-5 lg:px-3 pb-[60px] border-b border-b-darkGray">
                <img className="w-2/3 lg:w-auto" src={text_img}></img>
            </div>

            <div className="max-w-screen-xl container mx-auto px-5 lg:px-3">
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <div className="col-span-1">

                    </div>
                    <div className="col-span-1">
                        <p className="text-22 text-black mt-[60px]">
                            At Pi&People, our team of developers collaborates closely with designers, strategists, and analysts to ensure that our digital products are built exactly as intended during the design phase, without shortcuts or simplifications.
                            <br /><br />
                            We are guided by a user-centered design philosophy that prioritizes productivity and revenue growth. While we take pride in our remarkable expertise and ingenuity, we continuously strive to surpass our past achievements.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Philosohy