import React from "react";
import gradient from '../assets/images/bg-services.svg'

const Services = () => {
    return (
        <section id="services" className="border-y border-y-darkGray relative py-[70px] lg:py-[143px]">
            <div className="max-w-screen-xl container mx-auto px-5 lg:px-3">
                <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="col-span-1">
                        <h2 className="uppercase leading-none font-700 md:font-600 text-40 lg:text-57">smart <br /> development</h2>
                    </div>
                    <div className="col-span-1">
                        <h6 className="text-16 lg:text-22 mt-[30px] md:mt-0 pl-0 md:pl-[50px] lg:pl-[150px]">We combine distinctive design and advanced technology to build digital products that are true to their intended design, without compromising on quality by taking shortcuts or simplifying the process.</h6>
                        <div className="flex gap-5 lg:gap-8 mt-[40px] lg:mt-[80px]">
                            <p className="uppercase mt-3 lg:mt-0 text-16 lg:text-22">areas</p>
                            <div className="flex flex-wrap gap-2 lg:gap-6 mt-1">
                                <div className="border border-white rounded-full py-2 px-3 flex items-center">
                                    <p className="uppercase text-14 lg:text-16">Loyalty</p>
                                </div>
                                <div className="border border-white rounded-full py-2 px-3 flex items-center">
                                    <p className="uppercase text-14 lg:text-16">Finance</p>
                                </div>
                                <div className="border border-white rounded-full py-2 px-3 flex items-center">
                                    <p className="uppercase text-14 lg:text-16">Education</p>
                                </div>
                                <div className="border border-white rounded-full py-2 px-3 flex items-center">
                                    <p className="uppercase text-14 lg:text-16">Social</p>
                                </div>
                                <div className="border border-white rounded-full py-2 px-3 flex items-center">
                                    <p className="uppercase text-14 lg:text-16">Entertainment</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-left absolute -z-50 -top-[350px]'>
                <img className='-ml-[50px] w-[900px] h-[1200px]' src={gradient} />
            </div>
        </section>
    )
}

export default Services