import React from "react";

import looper from '../assets/images/looper.svg'
import stack_1 from '../assets/images/stack-5.svg'
import stack_2 from '../assets/images/stack-4.svg'
import stack_3 from '../assets/images/stack-3.svg'
import stack_4 from '../assets/images/stack-2.svg'
import stack_5 from '../assets/images/stack-1.svg'

import stack_6 from '../assets/images/stack-6.svg'
import stack_7 from '../assets/images/stack-7.svg'
import stack_8 from '../assets/images/stack-8.svg'
import stack_9 from '../assets/images/stack-9.svg'
import stack_10 from '../assets/images/stack-10.svg'

import card_1 from '../assets/images/card-1.svg'
import card_2 from '../assets/images/card-2.svg'
import card_3 from '../assets/images/card-3.svg'
import card_4 from '../assets/images/card-4.svg'


const Slider = () => {
    return (
        <section id="sliders" className="relative py-[50px] lg:py-[123px]">
            <div className="max-w-screen-xl container mx-auto px-5 lg:px-3">
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <div className="col-span-1"></div>
                    <div className="col-span-1 flex">
                        <img src={looper}></img>
                        <h6 className="text-20 md:text-24 pl-[35px] md:pl-[80px] max-w-[413px] mt-3 md:mt-7">
                            <span className="text-lightHex">WE THINK AND SPEAK MAINLY</span> <br /> SWIFT, KOTLIN, NODE, REACT. THAT’S EVERYTHING YOU NEED!
                        </h6>
                    </div>
                </div>
            </div>
            <div className="relative flex overflow-x-hidden mt-[30px] md:mt-[80px]">
                <div className="py-12 animate-marquee whitespace-nowrap flex gap-4 items-center">
                    <img className="mx-4 max-w-[120px]" src={stack_1}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_2}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_3}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_4}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_5}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_1}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_2}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_3}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_4}></img>
                    <img className="mx-4 max-w-[120px] h-max-[50px]" src={stack_5}></img>
                </div>

                <div className="absolute top-0 py-12 animate-marquee2 whitespace-nowrap flex gap-4 items-center">
                    <img className="mx-4 max-w-[120px] h-max-[50px]" src={stack_1}></img>
                    <img className="mx-4 max-w-[120px] h-max-[50px]" src={stack_2}></img>
                    <img className="mx-4 max-w-[120px] h-max-[50px]" src={stack_3}></img>
                    <img className="mx-4 max-w-[120px] h-max-[50px]" src={stack_4}></img>
                    <img className="mx-4 max-w-[120px] h-max-[50px]" src={stack_5}></img>
                    <img className="mx-4 max-w-[120px] h-max-[50px]" src={stack_1}></img>
                    <img className="mx-4 max-w-[120px] h-max-[50px]" src={stack_2}></img>
                    <img className="mx-4 max-w-[120px] h-max-[50px]" src={stack_3}></img>
                    <img className="mx-4 max-w-[120px] h-max-[50px]" src={stack_4}></img>
                    <img className="mx-4 max-w-[120px] h-max-[50px]" src={stack_5}></img>
                </div>
            </div>
            <div className="relative flex overflow-x-hidden mt-0">
                <div className="animate-marquee whitespace-nowrap flex gap-4 items-center">
                    <img className="mx-4 max-w-[120px]" src={stack_6}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_7}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_8}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_9}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_10}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_6}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_7}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_8}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_9}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_10}></img>
                </div>

                <div className="absolute top-0 animate-marquee2 whitespace-nowrap flex gap-4 items-center">
                    <img className="mx-4 max-w-[120px]" src={stack_6}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_7}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_8}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_9}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_10}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_6}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_7}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_8}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_9}></img>
                    <img className="mx-4 max-w-[120px]" src={stack_10}></img>
                </div>
            </div>
            <div className="max-w-screen-xl container mx-auto px-5 lg:px-3 mt-[50px] lg:mt-[120px]">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <a className="cursor-pointer col-span-1">
                        <img src={card_1}></img>
                    </a>
                    <a className="cursor-pointer col-span-1">
                        <img src={card_2}></img>
                    </a>
                    <a className="cursor-pointer col-span-1">
                        <img src={card_3}></img>
                    </a>
                    <a className="cursor-pointer col-span-1">
                        <img src={card_4}></img>
                    </a>
                </div>
            </div>
        </section >
    )
}

export default Slider