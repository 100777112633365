import React from 'react';
import Header from './components/header';
import Hero from './components/hero';
import './App.css';

import gradient from './assets/images/bg-hero.svg'
import down_arrow from './assets/images/down-arrow.svg'
import DevelopmentProcess from './components/process';
import Services from './components/services';
import Slider from './components/marquees';
import Team from './components/team';
import Partners from './components/partners';
import Testimonials from './components/testimonials';
import Philosohy from './components/philosophy';
import FollowUs from './components/follow';
import Contact from './components/contact';
import Footer from './components/footer';

function App() {
  return (
    <div className='relative'>
      <Header></Header>
      <div className="max-w-screen-xl container mx-auto px-5 lg:px-3">
        <Hero></Hero>
      </div>
      <div className='bg-left absolute -z-50 overflow-hidden top-[150px]'>
        <img className='ml-[150px] md:ml-[400px] lg:ml-[500px] xl:ml-[1100px] w-[900px] lg:w-[1200px]' src={gradient} />
      </div>
      <div className='flex items-center justify-center h-[50px] w-[30px] mx-auto border border-white rounded-full'>
        <img src={down_arrow}></img>
      </div>

      <div className="max-w-screen-xl container mx-auto px-5 lg:px-3">
        <DevelopmentProcess></DevelopmentProcess>
      </div>
      <Services></Services>
      <Slider></Slider>
      <Team></Team>
      <Partners></Partners>
      <Testimonials></Testimonials>
      <Philosohy></Philosohy>
      <FollowUs></FollowUs>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  );
}

export default App;