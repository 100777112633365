import React from "react"

import dev_1 from '../assets/images/development-1.svg'
import dev_2 from '../assets/images/development-2.svg'
import dev_3 from '../assets/images/development-3.svg'
import dev_4 from '../assets/images/development-4.svg'

const DevelopmentProcess = () => {
    return (
        <section id="developmentProcess" className="text-center mb-[70px] lg:mb-[120px]">
            <h2 className="uppercase text-16 md:text-24 lg:text-32 mt-9">Development process</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 mt-8">
                <div className="col-span-1 flex flex-col justify-center md:border-r border-r-white py-2">
                    <img className="h-[45px] mb-[28px] lg:mb-[48px]" src={dev_1}></img>
                    <h5 className="text-16 uppercase mb-[30px] lg:mb-0">Planning <br /> & <br /> Information</h5>
                </div>
                <div className="col-span-1 flex flex-col justify-center md:border-r border-r-white py-2">
                    <img className="h-[45px] mb-[28px] lg:mb-[48px]" src={dev_2}></img>
                    <h5 className="text-16 uppercase mb-[30px] lg:mb-0">Design <br /> & <br /> content creation</h5>
                </div>
                <div className="col-span-1 flex flex-col justify-center md:border-r border-r-white py-2">
                    <img className="h-[45px] mb-[28px] lg:mb-[48px]" src={dev_3}></img>
                    <h5 className="text-16 uppercase mb-[30px] lg:mb-0">Development <br /> & <br /> Testing</h5>
                </div>
                <div className="col-span-1 flex flex-col justify-center py-2">
                    <img className="h-[45px] mb-[28px] lg:mb-[48px]" src={dev_4}></img>
                    <h5 className="text-16 uppercase mb-[30px] lg:mb-0">Launch <br /> & <br /> Maintenance</h5>
                </div>
            </div>
        </section>
    )
}

export default DevelopmentProcess